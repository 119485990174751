import { PUB_SUB_EVENTS } from '../helper/constants';
import { publish } from '../helper/pubsub';

/* Variant Select & Radios */
class VariantSelects extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('change', this.onVariantChange);
  }

  onVariantChange() {
    this.updateOptions();
    this.updateMasterId();
    //this.toggleAddButton(true, '', false);
    this.updatePickupAvailability();
    this.removeErrorMessage();
    this.updateVariantInput();
    this.updateVariantStatuses();

    if (!this.currentVariant) {
      //this.toggleAddButton(true, '', true);
      //this.setUnavailable();
    } else {
      this.updateURL();
      this.updateVariantInput();
      this.renderProductInfo();
      this.updateShareUrl();
      this.updateOptions();
      //this.updateVariantTitle();
    }
  }

  updateOptions() {
    this.options = Array.from(this.querySelectorAll('select'), (select) => select.value);
  }

  updateMasterId() {
    this.currentVariant = this.getVariantData().find((variant) => {
      return !variant.options
        .map((option, index) => {
          return this.options[index] === option;
        })
        .includes(false);
    });
  }

  updateURL() {
    if (!this.currentVariant || this.dataset.updateUrl === 'false') return;
    window.history.replaceState({}, '', `${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateShareUrl() {
    const shareButton = document.getElementById(`Share-${this.dataset.section}`);
    if (!shareButton || !shareButton.updateUrl) return;
    shareButton.updateUrl(`${window.shopUrl}${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateVariantInput() {
    const productFormId = this.getAttribute('form');
    const productForm = document.querySelector(`#${productFormId}`);
    const input = productForm.querySelector('input[name="id"]');
    if (this.currentVariant) input.value = this.currentVariant.id;
    input.dispatchEvent(new Event('change', { bubbles: true }));
  }

  updateVariantStatuses() {
    const selectedOptionOneVariants = this.variantData.filter(
      (variant) => this.querySelector(':checked').value === variant.option1
    );
    const inputWrappers = [...this.querySelectorAll('.product-form__input')];

    inputWrappers.forEach((option, index) => {
      if (index === 0) return;
      const optionInputs = [...option.querySelectorAll('input[type="radio"], option, li')];

      const previousOptionSelected =
        inputWrappers[index - 1].querySelector(':checked')?.value ||
        inputWrappers[index - 1].querySelector('select')?.value ||
        inputWrappers[index - 1].querySelector('li[aria-selected="true"]')?.dataset.value;
      const availableOptionInputsValue = selectedOptionOneVariants
        .filter((variant) => variant.available && variant[`option${index}`] === previousOptionSelected)
        .map((variantOption) => variantOption[`option${index + 1}`]);
      this.setInputAvailability(optionInputs, availableOptionInputsValue);
    });
  }

  setInputAvailability(listOfOptions, listOfAvailableOptions) {
    listOfOptions.forEach((input) => {
      if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
        input.innerText = input.getAttribute('value');
      } else {
        input.innerText = window.variantStrings.unavailable_with_option.replace('[value]', input.getAttribute('value'));
      }
    });
  }

  updatePickupAvailability() {
    const pickUpAvailability = document.querySelector('pickup-availability');
    if (!pickUpAvailability) return;

    if (this.currentVariant && this.currentVariant.available) {
      pickUpAvailability.fetchAvailability(this.currentVariant.id);
    } else {
      pickUpAvailability.removeAttribute('available');
      pickUpAvailability.innerHTML = '';
    }
  }

  removeErrorMessage() {
    const section = this.closest('section');
    if (!section) return;

    const productForm = section.querySelector('product-form');
    if (productForm) productForm.handleErrorMessage();
  }

  renderProductInfo() {
    const requestedVariantId = this.currentVariant.id;
    const sectionId = this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section;

    fetch(
      `${this.dataset.url}?variant=${requestedVariantId}&section_id=${
        this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section
      }`
    )
      .then((response) => response.text())
      .then((responseText) => {
        // prevent unnecessary ui changes from abandoned selections
        if (this.currentVariant.id !== requestedVariantId) return;

        const html = new DOMParser().parseFromString(responseText, 'text/html');
        const destination = document.getElementById(`price-${this.dataset.section}`);
        const source = html.getElementById(
          `price-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
        );
        const skuSource = html.getElementById(
          `Sku-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
        );
        const skuDestination = document.getElementById(`Sku-${this.dataset.section}`);
        const inventorySource = html.getElementById(
          `Inventory-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
        );
        const inventoryDestination = document.getElementById(`Inventory-${this.dataset.section}`);

        if (source && destination) destination.innerHTML = source.innerHTML;
        if (inventorySource && inventoryDestination) inventoryDestination.innerHTML = inventorySource.innerHTML;
        if (skuSource && skuDestination) {
          skuDestination.innerHTML = skuSource.innerHTML;
          skuDestination.classList.toggle('visibility-hidden', skuSource.classList.contains('visibility-hidden'));
        }

        const price = document.getElementById(`price-${this.dataset.section}`);

        if (price) price.classList.remove('visibility-hidden');

        if (inventoryDestination)
          inventoryDestination.classList.toggle('visibility-hidden', inventorySource.innerText === '');

        const addButtonUpdated = html.getElementById(`ProductSubmitButton-${sectionId}`);

        this.toggleAddButton(
          addButtonUpdated ? addButtonUpdated.hasAttribute('disabled') : true,
          window.variantStrings.soldOut
        );

        publish(PUB_SUB_EVENTS.variantChange, {
          data: {
            sectionId,
            html,
            variant: this.currentVariant
          }
        });
      });
  }

  toggleAddButton(disable = true, text, modifyClass = true) {
    const productFormId = this.getAttribute('form');
    const productForm = document.querySelector(`#${productFormId}`);
    if (!productForm) return;
    const addButton = productForm.querySelector('[name="add"]');
    const addButtonText = productForm.querySelector('[name="add"] > span');
    if (!addButton) return;
    if (disable) {
      addButton.setAttribute('disabled', 'disabled');
      if (text) addButtonText.innerHTML = text;
    } else {
      addButton.removeAttribute('disabled');
      addButtonText.innerHTML = window.variantStrings.addToCart;
    }

    if (!modifyClass) return;
  }

  setUnavailable() {
    const button = document.getElementById(`product-form-${this.dataset.section}`);
    const addButton = button.querySelector('[name="add"]');
    const addButtonText = button.querySelector('[name="add"] > span');
    const price = document.getElementById(`price-${this.dataset.section}`);
    const inventory = document.getElementById(`Inventory-${this.dataset.section}`);
    const sku = document.getElementById(`Sku-${this.dataset.section}`);

    if (!addButton) return;
    addButtonText.innerHTML = window.variantStrings.unavailable;
    if (price) price.classList.add('visibility-hidden');
    if (inventory) inventory.classList.add('visibility-hidden');
    if (sku) sku.classList.add('visibility-hidden');
  }

  getVariantData() {
    this.variantData = JSON.parse(this.querySelector('[type="application/json"]').innerHTML);
    return this.variantData;
  }
}

customElements.define('variant-selects', VariantSelects);

class VariantRadios extends VariantSelects {
  constructor() {
    super();
    this.limited;
    this.detailsSelects = this.querySelectorAll('variant-details-select');
  }

  connectedCallback() {
    this.setScrollLeft();
  }

  setScrollLeft() {
    let limitedContainer = document.querySelector('#limited-swatch-container');
    if (limitedContainer) {
      let limitedCheckedRadio;
      let limitedItemContainer = limitedContainer.querySelector('.item-container');
      if (limitedItemContainer) limitedCheckedRadio = limitedContainer.querySelector('input[type="radio"]:checked');

      limitedItemContainer.addEventListener('scroll', function () {
        var maxScrollLeft = limitedItemContainer.scrollWidth - limitedItemContainer.clientWidth;
        const shadow = limitedContainer.querySelector('.variant-shadow');
        console.log(limitedItemContainer.scrollWidth - limitedItemContainer.clientWidth);

        if (limitedItemContainer.scrollLeft > 0) {
          shadow.classList.add('opacity-0');
        }
        if (limitedItemContainer.scrollLeft < maxScrollLeft) {
          shadow.classList.remove('opacity-0');
        }
      });
    }

    let classicContainer = document.querySelector('#classic-swatch-container');

    let classiCheckedRadio;
    if (classicContainer) classicContainer.querySelector('input[type="radio"]:checked');

    //  if (limitedItemContainer) {
    //    limitedContainer.scrollLeft = limitedCheckedRadio.parentElement.offsetLeft - 20;
    //  }
    //  if (classiCheckedRadio) {
    //    classicContainer.scrollLeft = classiCheckedRadio.parentElement.offsetLeft - 20;
    //  }
  }

  setInputAvailability(listOfOptions, listOfAvailableOptions) {
    listOfOptions.forEach((input) => {
      // Check if the current input is a descendant of any 'variant-details-select' elements
      const isDetailsSelect = Array.from(this.detailsSelects).some((detailsSelect) => detailsSelect.contains(input));

      // Skip this input if it is a descendant of 'variant-details-select'
      if (isDetailsSelect) {
        return;
      }

      if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
        input.classList.remove('disabled');
        this.toggleAddButton(true, '', false);
      } else {
        input.classList.add('disabled');
        this.toggleAddButton(true, '', true);
      }
    });
  }

  updateOptions() {
    const inputs = Array.from(this.querySelectorAll('.variant-radios, variant-details-select select'));
    this.options = inputs.map((input) => {
      if (input.classList.contains('variant-radios')) {
        return Array.from(input.querySelectorAll('input')).find((radio) => radio.checked).value;
      } else if (input.tagName === 'SELECT') {
        return input.value;
      }
    });
    console.log(this.options);

    // Update the selected value display
    const selectedValueDisplay = this.querySelector('#variantOptions #selected-value');
    if (selectedValueDisplay) {
      selectedValueDisplay.textContent = this.options.join(' / ');
    }
  }
}

customElements.define('variant-radios', VariantRadios);
